<template>
  <div style="background-color: #171726; min-height: 100vh">
    <div class="cash_title" style="background-color: #171726">
      <img class="cash_back" src="img/backw.png" alt="" @click="closePage" />
      <span style="color: #ffffff">兑换记录</span>
    </div>
    <div class="title_top" style="background-color: #171726">
      <div class="padding-lr-sm margin-bottom-xl">
        <!-- <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="fetchData"> -->
        <div
          class="drawLog_item display_flex justify-content_flex-justify align-items_center"
          v-for="(item, index) in listData"
          :key="index"
          style="background-color: rgba(255, 255, 255, 0.04); box-shadow: none"
        >
          <div class="drawLog_red display_flex align-items_center">
            <div class="margin-left-xs" style="padding-left: 10px">
              <span style="color: rgba(255, 255, 255, 0.7)">{{
                item.created_at
              }}</span>
              <p style="color: rgba(255, 255, 255, 1); font-size: 16px">
                兑换金币：{{ item.diamonds }}
              </p>
            </div>
          </div>
          <div class="drawLog_right display_flex align-items_center">
            <img
              style="margin-right: 6px; width: 20px; height: 20px"
              src="img/money_icon.png"
              alt=""
            />
            <p style="color: rgba(255, 255, 255, 0.7)">-</p>
            <p style="color: rgba(255, 255, 255, 1)">{{ item.money }}</p>
          </div>
        </div>
        <!-- </van-list> -->
        <div class="noData margin-top-xl" v-if="noData">
          <img src="img/noData.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import "@/assets/css/base.css";
import "@/assets/css/index.css";
import "@/assets/css/game.css";
import { exchangeRecord } from "@/api/api";
import NavBar from "@/components/navBar.vue";
export default {
  name: "exchangeRecord",
  components: {
    NavBar,
  },
  data() {
    return {
      listData: [],
      noData: false,
      from:''
    };
  },
  created() {
    let access_token = this.$route.query.access_token;
    localStorage.setItem("access_token", access_token);
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      exchangeRecord()
        .then(
          (response) => {
            // console.log(response)
            this.listData = response.data;
            // 数据全部加载完成

            if (this.listData.length == 0) {
              this.noData = true;
            }
          },
          (error) => {
            console.log(error);
            this.$toast.fail(error);
          }
        )
        .catch((error) => {
          this.$toast.fail(error);
        });
    },
    whyNo(remark) {
      this.$toast.fail(remark);
    },
    closePage() {
      if (this.from == "h5") {
        this.$router.go(-1);
      } else {
        let params = "调用";
        this.$bridge.callhandler("closePage", params, (data) => {
          // 处理返回数据
        });
      }
    },
  },
};
</script>
<style>
.van-toast__text {
  white-space: normal;
  word-break: break-all;
}
</style>